<template>
  <div style="background-color: #FAFAFA;">
      <component :is="componentsList[isChooseIndex]" @tabChange="tabChange" ></component>
  </div>
</template>

<script>
  export default {
    name: "ScienceServices",
    components:{
      'ScienceServiceIndex':() => import('@/components/index/ScienceServices/ScienceServiceIndex.vue'),
      'ScienceServiceList':() => import('@/components/index/ScienceServices/ScienceServiceList.vue'),
      'ScienceServiceDetail':() => import('@/components/index/ScienceServices/ScienceServiceDetail.vue'),
      'ScienceServicePay':() => import('@/components/index/ScienceServices/ScienceServicePay.vue')
    },
    data() {
      return {
        isChooseIndex:0,
        componentsList:['ScienceServiceIndex', 'ScienceServiceList', 'ScienceServiceDetail', 'ScienceServicePay'],
        valueDisabled:2,

        fwfl: [
          '全部服务', '专利服务', '商标服务', '版权服务', '科技项目服务'
        ],
        fwbq: [
          '全部标签', '服务标签'
        ],
        message: "",
        fwbqChoice: 0,
        priceFlag: true,
        xiaoliangFlag: true
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if (JSON.stringify(to.params) !== '{}') {
            let pagename = to.params.pageId;
            this.serviceType = to.query.serviceType;
            if(pagename === 'serviceList') {
              this.isChooseIndex = 1
            } else if(pagename === 'serviceDetail') {
              this.isChooseIndex = 2
            } else if (pagename === 'servicePay') {
              this.isChooseIndex = 3
            }else {
              this.isChooseIndex = 0
            }
          }
        },
      }
    },
    methods: {
      fwflChange(index) {
        this.fwflChoice = index
      },
      fwbqChange(index) {
        this.fwbqChoice = index
      },
      tabChange(index) {
        this.isChooseIndex = index
      }
    }
  }
</script>

<style scoped lang="scss">
  .pay_div {
    display: flex;
    height: 60px;
    width: 180px;
    border: 1px solid rgb(205, 240, 243);
    margin-left: 20px;
    font-size: 20px;
    align-items: center;
    cursor: pointer;

  }

  .div_button {
    cursor: pointer;
    border-width: 1px;
    border-color: rgb(220, 220, 220);
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
    width: 114px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .div_button_select {
    cursor: pointer;
    border-width: 1px;
    border-color: #FB7B3C;
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
    width: 114px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #FB7B3C;
  }

  .button_style {
    font-size: 12px;
    margin-left: 10px;
    display: inline-block;
    border-radius: 2px;
    background-color: rgb(54, 194, 207);
    width: 75px;
    height: 40px;
    z-index: 51;
    line-height: 40px;
    text-align: center;
    color: white;
    cursor: pointer;
  }

  #button_style div:nth-child(1) {
    margin-left: 50px;
  }

  #button_style div:nth-child(2) {
    margin-left: 25px;
  }

  .service_list > div:hover {
    box-shadow: 0 6px 20px -6px rgba(0, 0, 0, .1)
  }

  #order > span {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }

  .service_div {
    font-size: 14px;
    line-height: 50px;
    & span {
      display: inline-block;
      height: 50px;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .select_span {
    color: rgb(252, 117, 0);
    border-bottom: 2px solid rgb(252, 117, 0);
  }

  .dpyx {
    & span {
      margin: 0 5px;
    }
  }

  .left_desc {
    padding-left: 20px;
    padding-top: 5px;
  }

  .rmzl_content_real {
    padding: 20px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;

  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .rmzl_title_price .desc {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;;
    overflow: hidden;
  }

  .rmzl_image {
    width: 150px;
    height: 180px;
    float: left;
  }

  .rmzl_title_price {
    width: 228px;
    height: 180px;
    line-height: 1;
    float: left;
    box-sizing: border-box;
    padding: 20px 15px;
  }

  .div_center {
    width: 100%;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }
</style>
